<!-- =========================================================================================
     File Name: categoryList.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- CATEGORY LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="row form-title">
          <div class="col-4">
            <h4>Categories</h4>
          </div>

          <div class="col-8 d-flex align-items-center justify-content-end">
            <div class="mr-4 w-50">
              <multiselect
                v-model="projects_value"
                track-by="project_id"
                label="project_name"
                :options="projects"
                name="Project Name"
                placeholder="Filter by Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                @input="filterCategoryList"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.project_name
                }}</template>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span
                >
              </multiselect>
            </div>
            <div>
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>

            <div>
              <vx-tooltip text="Export Excel">
                <a
                  :class="projectID == null ? 'inactive-link' : ''"
                  :href="
                    excelurl +
                    `/v2/category/excel/download?project_id=${this.projectID}&organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4" :disabled="projectID == null">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                    `/v2/category/excel/download?project_id=${this.projectID}&organization_id=${organization_id}&token=${temp_token}&archive=4&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="categories"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";

import categoryStatus from "./categoryStatus.vue";
import EditCategory from "./EditCategory.vue";
import categoryArchive from "./categoryArchive.vue";
import addSubCategory from "./addSubCategory.vue";
import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { filter } from "minimatch";

export default {
  components: {
    AgGridVue,
    DatetimePickerStartEnd,
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL(),
      archive: this.listType,
      User: "Select User",
      categories: [],
      projects: [],
      projects_value: [],
      form: {},
      searchQuery: "",
      projectID: null,
      totalPages: 0,
      gridOptions: {},
      filterPayload: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [],
      contacts: contacts,
      organization_id: "",
    };
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    // console.log('this.$store.getters.getProjectID @@@@ :', this.$store.getters.getProjectID);
    if (this.$store.getters.getProjectID) {
      // setTimeout(
      //   () =>
      //     eventBus.$emit(
      //       "refreshTableCategoryList",
      //       this.$store.getters.getProjectID
      //     ),
      //   1
      // );
      this.projectID = this.$store.getters.getProjectID;
      this.getCategoryList(this.currentPage);
    }
    eventBus.$on("refreshTablecat", (e) => {
      this.getCategoryList(this.currentPage);
    });
    eventBus.$on("refreshTableCategoryList", (ID) => {
      this.projectID = ID;
      this.getCategoryList(this.currentPage);
    });

    this.columnDefs = [
      {
        headerName: "Name",
        field: "category_name",
        width: 200,
        minWith: 200,
        filter: false,
        suppressSizeToFit: true,
        // checkboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // headerCheckboxSelection: true,
      },
      {
        headerName: "Project",
        field: "project_name",
        filter: false,
        width: 250,
        minWith: 200,
      },
      {
        headerName: "Description",
        field: "category_description",
        filter: false,
        width: 375,
        minWith: 375,
      },

      {
        headerName: "Status",
        field: "",
        filter: false,
        hide: this.archive == "archive" ? true : false,
        width: 175,
        minWith: 175,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: categoryStatus,
        // cellRenderer: function(params) {
        //   return '<label class="switch"> <input type="checkbox" checked> <span class="slider round"></span> </label>';
        // }
      },
      {
        headerName: `Subcategory`,
        field: "category_id",
        filter: false,
        hide: this.archive == "archive" ? true : false,
        width: 200,
        minWith: 200,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: addSubCategory,
        // cellRenderer: function(params) {
        //   return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        // }
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        hide: this.archive == "archive" ? true : false,
        width: 150,
        minWith: 150,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditCategory,
        // cellRenderer: function(params) {
        //   return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        // }
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        minWith: 200,
        width: this.archive ? 175 : 175,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: categoryArchive,
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        // this.gridOptions.columnApi.setColumnPinned("email", null);
      } else {
        //  this.gridOptions.columnApi.setColumnPinned("email", "left");
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getCategoryList(val);
          return val;
        } else {
          return val;
        }
        // this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    // updateSearchQuery(val) {
    //   this.gridApi.setQuickFilter(val);
    // },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(
        val,
        "category/getProjectMainCategories",
        this.filterPayload
      )
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.categories = data.data;
          } else {
            this.categories = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getCategoryList: function (currentPage) {
      let filter = {
        page: currentPage,
        project_id: this.projectID,
      };
      this.filterPayload = {
        project_id: this.projectID,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      this.$vs.loading();
      CategoryService.getAllCategories(filter)
        .then((response) => {
          const { data } = response;
          this.$vs.loading.close();
          if (data.Status == false || data.Status == "false") {
          } else {
            // if (this.archive == "archive") {
            //   data.data = data.data.map(x => {
            //     x.category_active = 2;
            //     return x;
            //   });
            // }
            this.categories = data.data;
            // this.categories = data.data.filter(
            //   x => String(x.parent_id) === "0"
            // );
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterCategoryList: function () {
      let id = this.projects_value.project_id;
      this.$store.dispatch("SetProjectID", id);
      setTimeout(() => {
        eventBus.$emit("refreshTableCategoryList", id);
      }, 1);
    },
  },
  mounted() {
    this.getProjectsList();
    if (this.$store.getters.getProjectID) {
      setTimeout(
        () =>
          eventBus.$emit(
            "refreshTableCategoryList",
            this.$store.getters.getProjectID
          ),
        1
      );
    }
    if (this.checkPermission(["category", "GET"])) {
      this.getCategoryList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
<style scoped>
.inactive-link {
  pointer-events: none;
}
</style>
